<template>
    <cluji-card :title="title" :subtitle="subtitle" :starts-open="startsOpen" @collapse="propagateCollapse">
        <template #title>
            <slot name="title">
                {{ title }}
            </slot>
        </template>
        <slot name="body_top"/>
        <b-input-group class="float-left col-md-6 pl-0">
            <b-dropdown variant="light" slot="prepend">
                <template slot="button-content">
                    <font-awesome-icon icon="download"/>
                </template>
                <b-dropdown-item @click="downloadPDF">PDF</b-dropdown-item>
                <b-dropdown-item @click="downloadCSV">CSV</b-dropdown-item>
            </b-dropdown>
            <b-input-group-text slot="prepend">
                <font-awesome-icon icon="search"/>
            </b-input-group-text>
            <b-form-input type="text" placeholder="Recherche" v-model="filter" @submit.stop/>
        </b-input-group>
        <div class="float-right">
            <b-pagination :total-rows="totalItems" v-model="currentPage" :per-page="perPage"/>
        </div>
        <div class="table-holder">
            <b-table striped outlined :items="items" :fields="fields" :per-page="perPage"
                     :sort-compare="bTableSortCompare" thead-class="lh-auto"
                     :current-page="currentPage" :filter="filter" @filtered="onFiltered">
                <template :slot="'cell(' + customEl + ')'" slot-scope="row" v-for="customEl in customRendered">
                    <slot :name="customEl" v-bind:row="row"/>
                </template>
                <template slot="details" slot-scope="row" v-if="row.item.details">
                    <b-button size="sm" @click="row.toggleDetails" class="mr-2"
                              :variant="row.detailsShowing ? 'outline-secondary' : 'secondary'">
                        <font-awesome-icon :icon="row.detailsShowing ? 'minus' : 'plus'" class="mr-2"/>
                        {{ details }}
                    </b-button>
                </template>
                <template slot="row-details" slot-scope="row" v-if="row.item.details">
                    <b-card>
                        <b-row class="mb-1" v-for="item in row.item.details" :key="item.key">
                            <b-col sm="6" class="text-sm-right">
                                <b>{{ item.key }} :</b>
                            </b-col>
                            <b-col>
                                {{ item.value }}
                            </b-col>
                        </b-row>
                    </b-card>
                </template>
                <template slot="cell(actions)" slot-scope="row" v-if="actions.length">
                    <b-button size="sm" v-for="action in actions" :key="action.key"
                              :variant="action.color(row) || 'default'" class="mr-1"
                              v-if="action.display ? action.display(row) : true"
                              :disabled="action.disabled ? action.disabled(row) : false"
                              v-b-tooltip.hover="action.tooltip || null"
                              @click="action.handler.call(this, row)">
                        <font-awesome-icon :icon="action.icon" v-if="action.icon"/>
                        {{ action.label ? action.label(row) : '' }}
                    </b-button>
                </template>
            </b-table>
        </div>
    </cluji-card>
</template>

<script>
    import {formatForCsv}      from '@/util/text';
    import pdf                 from '@/util/pdf';
    import {bTableSortCompare} from '@/util/sort';
    import Papa                from 'papaparse';
    import slug                from 'slug';

    const ClujiCard = () => import('@/components/ClujiCard');

    export default {
        name: "CardTableSearch",
        components: {ClujiCard},
        props: {
            startsOpen: {
                type: Boolean,
                default: true
            },
            title: {
                type: String,
                default: ''
            },
            subtitle: {
                type: String,
                default: ''
            },
            fields: {
                type: Array,
                default: () => []
            },
            actions: {
                type: Array,
                default: () => []
            },
            items: {
                type: Array,
                default: () => []
            },
            perPage: {
                type: Number,
                default: 25
            },
            details: {
                type: String,
                default: 'Détails'
            },
            customRendered: {
                type: Array,
                default: () => []
            },
            exportColumns: {
                type: Array,
                default: () => []
            },
            pdfExportOptions: {
                type: Object,
                default: () => ({})
            }
        },
        data: () => ({
            totalItems: 0,
            currentPage: 1,
            filteredItems: [],
            filter: null
        }),
        watch: {
            // Not a computed value otherwise the number of pages in pagination is not updated when a search is applied
            items(newVal) {
                this.totalItems = newVal.length;
            }
        },
        methods: {
            propagateCollapse(val) {
                this.$emit('collapse', val);
            },
            downloadCSV() {
                // Encode data in CSV and build a URL to access it
                let csvColumns = this.generateExportHeaders();
                let items      = this.filteredItems.length ? this.filteredItems : this.items;
                const url      = encodeURI(
                    "data:text/csv;charset=utf-8," +
                    Papa.unparse(
                        csvColumns
                            ? [...items].map(item => {
                                let formatted = {};
                                csvColumns.map(col => {
                                    formatted[col.label || col.key] = formatForCsv(
                                        item[col.key],
                                        col.formatter,
                                        col.key,
                                        item
                                    );
                                });
                                return formatted;
                            })
                            : [...items],
                        {header: true}
                    )
                );
                // Create a temporary link to access the newly created URL
                const link     = document.createElement('a');
                link.href      = url;
                link.setAttribute('download', slug(this.title) + '.csv');
                document.body.appendChild(link);
                link.click();
                link.remove();
            },
            downloadPDF() {
                let pdfColumns = this.generateExportHeaders();
                let items      = this.filteredItems.length ? this.filteredItems : this.items;
                let data       = pdfColumns
                    ? [...items].map(item => {
                        let formatted = [];
                        pdfColumns.map(col => {
                            formatted.push(formatForCsv(item[col.key], col.formatter, col.key, item) || " ");
                        });
                        return formatted;
                    })
                    : [...items];

                pdf.downloadJsTable(
                    pdfColumns.map(col => col.label || col.key),
                    data,
                    {title: this.title}
                );
            },
            generateExportHeaders() {
                let columns = this.exportColumns.length ? [...this.exportColumns] : [...this.fields];
                for (let i = columns.length - 1; i >= 0; i--) {
                    if (columns[i].key === 'actions') {
                        columns.splice(i, 1);
                        break;
                    }
                }

                return columns;
            },
            onFiltered(filteredItems) {
                this.totalItems    = filteredItems.length;
                this.filteredItems = filteredItems;
                this.currentPage   = 1;
            },
            bTableSortCompare
        }
    }
</script>

<style scoped>
    div.table-holder {
        overflow-x: auto;
        width: 100%;
    }
</style>